<template>
  <v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition">
    <v-card style="background-color: #eef5f9">
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="$emit('close', $event)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Ship Products</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="save"> Save </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-container>
        <PlainCard>
          <div class="invoice-details" style="display: grid; grid-template-columns: repeat(4, 1fr); grid-column-gap: 20px">
            <v-autocomplete :items="['Air', 'Ship']" label="Transport mode" v-model="shippingMethod" />
            <v-text-field label="Air/Ship No" required v-model="airNo" />
            <v-text-field label="Invoice No" required v-model="invoiceNo" />
            <v-text-field label="CTNS" required v-model="cartons" />
            <v-text-field label="C/O" required v-model="co" />
            <DatePicker :visible.sync="shippingDate_Dialog" label="Shipping Date" :date.sync="shippingDate" />
            <DatePicker :visible.sync="deliveryDate_Dialog" label="Expected Delivery Date" :date.sync="deliveryDate" />
            <v-text-field label="Total Amount" disabled required :value="totalProductsPrice()" />
          </div>
        </PlainCard>
      </v-container>
      <v-container>
        <PlainCard v-for="(item, i) in shippingData" :key="item.id" class="mb-5">
          <div class="invoice-details" style="display: grid; grid-template-columns: repeat(5, 1fr); grid-column-gap: 3px">
            <v-img class="img" contain height="150" :src="getImage(item.product.imageLink)" />
            <LabelText label="Product Name" :value="item.product.name" />
            <LabelText label="Product Code" :value="item.product.code" />
            <LabelText label="Branch Code" :value="item.branch.code" />
            <LabelText label="Order ID" :value="item.orderId" />
            <LabelText label="Price" :value="item.price" />
            <LabelText label="Total Amount" :value="item.total" />
            <LabelText label="Order Quantity" :value="item.remaining_qty" />
            <LabelText label="Shipped Total Price" :value="item.current_shipping_totalPrice" />
            <LabelText label="Previously Shipped Quantity" v-if="item.shipped_qty !== null" :value="item.shipped_qty" />
            <LabelText label="Current Shipping Quantity" :value="item.currentShippingQuantity == null ? 0 : item.currentShippingQuantity" />
            <LabelText label="Remaining Quantity" :value="remainingQuantity(item)" />
            <LabelText label="Excess Quantity" :value="item.excess_qty == null ? 0 : item.excess_qty" />
          </div>
          <v-divider></v-divider>
          <div class="shipping-item-list row mt-0">
            <div class="col-md 12 mt-0">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">CTN NO</th>
                      <th class="text-left">PCS/CTN</th>
                      <th class="text-left">CTNS</th>
                      <th class="text-left">QTY</th>
                      <th class="text-left">UNIT PRICE</th>
                      <th class="text-left">TOTAL PRICE</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="item" v-for="(subItem, index) in item.carton_details" :key="index">
                      <td>
                        <input type="text" v-model="subItem.ctnNo" required />
                      </td>
                      <td>
                        <input type="text" v-model="subItem.pcsCtn" required v-on:input="setQty(i, index)" />
                      </td>
                      <td>
                        <input type="text" v-model="subItem.ctns" v-on:input="setQty(i, index)" required />
                      </td>
                      <td>
                        <input readonly type="text" v-model="subItem.quantity" />
                      </td>
                      <td>
                        <input readonly type="text" v-model="item.price" />
                      </td>
                      <td>
                        <input readonly type="text" v-model.number="subItem.total" />
                      </td>
                    </tr>
                    <v-row class="center mt-2" justify="space-around">
                      <button type="button" class="btn btn-info ml-2" @click="onAddNewRow(item)">
                        <i class="fas fa-plus-circle"></i>
                        Add Row
                      </button>
                      <button type="button" class="btn btn-info" @click="removeRow(item, i)">
                        <i class="fas fa-minus-circle"></i>
                        Remove Row
                      </button>
                    </v-row>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </div>
        </PlainCard>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { getImage } from "../../../../common/utils";
import PlainCard from "../../../../components/PlainCard.vue";
import LabelText from "./LabelText.vue";
import DatePicker from "../../../../components/DatePicker.vue";
import { saveShipping } from "../lib";
import { mapActions } from "vuex";
export default {
  name: "NewShippingDialog",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    shippingData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      shippingDate_Dialog: false,
      shippingDate: "",
      deliveryDate_Dialog: false,
      deliveryDate: "",
      shippingMethod: "",
      airNo: "",
      invoiceNo: "",
      cartons: "",
      co: "",
    };
  },
  methods: {
    ...mapActions({
      setLoading: "setLoading",
    }),
    getImage: getImage,
    numberRule: (v) => {
      if (!v.trim()) return true;
      if (!isNaN(parseFloat(v)) && v >= 0 && v <= 999) return true;
      return "Number has to be between 0 and 999";
    },
    addNewRow() {
      let d = { ctnNo: null, pcsCtn: null, ctns: null, quantity: 0, total: 0 };
      this.$emit("add-data", d);
    },
    setQty(mainIndex, subIndex) {
      this.shippingData[mainIndex].carton_details[subIndex].quantity =
        this.shippingData[mainIndex].carton_details[subIndex].pcsCtn * this.shippingData[mainIndex].carton_details[subIndex].ctns;
      this.subTotal(mainIndex, subIndex);
      this.shippedTotal(mainIndex);
      this.cartShippedTotalPrice(mainIndex);

      this.remainingQuantity(mainIndex);
      this.excessQty(mainIndex);
      this.totalProductsPrice();
      this.$forceUpdate();
    },
    subTotal(mainIndex, subIndex) {
      this.shippingData[mainIndex].carton_details[subIndex].total = Number(
        (this.shippingData[mainIndex].price * this.shippingData[mainIndex].carton_details[subIndex].quantity).toFixed(2)
      );
    },
    remainingQuantity(item) {
      let _rQty = item.remaining_qty;
      _rQty = _rQty - (item.currentShippingQuantity == null ? 0 : item.currentShippingQuantity);

      return _rQty <= 0 ? 0 : _rQty;
    },
    shippedTotal(i) {
      this.shippingData[i].currentShippingQuantity = this.shippingData[i].carton_details.reduce((n, { quantity }) => n + quantity, 0);
    },
    cartShippedTotalPrice(i) {
      this.shippingData[i].current_shipping_totalPrice = this.shippingData[i].carton_details.reduce(
        (n, { total }) => (total ? n + total : n + 0),
        0
      );
    },
    excessQty(i) {
      let _val = this.shippingData[i].currentShippingQuantity + this.shippingData[i].shipped_qty;
      console.log(_val);
      this.shippingData[i].remaining_qty < _val
        ? (this.shippingData[i].excess_qty = _val - this.shippingData[i].quantity)
        : (this.shippingData[i].excess_qty = 0);
    },
    totalProductsPrice() {
      var v = this.shippingData?.map((i) => {
        return i.current_shipping_totalPrice;
      });
      return v.reduce((a, b) => a + b, 0);
    },
    onAddNewRow(item) {
      const data = {
        ctnNo: null,
        pcsCtn: null,
        ctns: null,
        quantity: 0,
        total: 0,
      };
      item.carton_details.push(data);
      this.$forceUpdate();
    },
    removeRow(item, mainIndex) {
      item.carton_details.pop();
      this.setQty(mainIndex, 0);
      this.$forceUpdate();
    },
    resetForm() {
      this.shippingDate_Dialog = false;
      this.shippingDate = "";
      this.deliveryDate_Dialog = false;
      this.deliveryDate = "";
      this.shippingMethod = "";
      this.airNo = "";
      this.invoiceNo = "";
      this.cartons = "";
      this.co = "";
    },
    async save() {
      this.setLoading(true);
      let finalData = {
        airShipNo: this.airNo,
        invoiceNo: this.invoiceNo,
        co: this.co,
        cartons: this.cartons,
        via: this.shippingMethod,
        shippingDate: this.shippingDate,
        totalItems: 0,
        total: this.totalProductsPrice(),
        expectedDeliveryDate: this.deliveryDate,
        shippingChild: [],
      };
      this.shippingData?.map((item) => {
        finalData.shippingChild.push({
          orderId: item.orderId,
          branchId: item.branch.id,
          productId: item.product.id,
          shippedQuantity: item.currentShippingQuantity,
          excessQuantity: item.excess_qty || 0,
          total: item.current_shipping_totalPrice,
          shippingCarton: item.carton_details,
        });
      });

      let res = await saveShipping(finalData);

      if (res.statusCode == 201) {
        this.setLoading(false);
        // this.dialog = false;
        this.$notify({
          title: res.status,
          text: res.message,
          type: "success",
        });

        this.$emit("success");
        this.resetForm();
      } else {
        this.setLoading(false);
        this.$notify({
          title: "Error",
          text: "Something went wrong, please try again",
          type: "success",
        });
      }

      // console.log(res);
    },
  },
  components: { PlainCard, LabelText, DatePicker },
};
</script>
<style scoped>
.item input {
  border-style: groove;
  width: 6rem;
  border-color: rgba(124, 124, 124, 0.487);
  padding-left: 0.3rem;
  border-width: 1px;
  border-radius: 5px;
}
.invoice-details .v-text-field--outlined >>> fieldset {
  border-style: none !important;
  border-color: rgba(255, 255, 255, 0.986) !important;
}

.img {
  grid-area: 1 / 1 / 4 / 2;
}
</style>
