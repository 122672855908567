<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="8">
        <div class="block">
          <el-date-picker v-model="filters.dates" type="daterange" align="right" start-placeholder="Start Date"
            end-placeholder="End Date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" clearable @change="onInit()">
          </el-date-picker>
        </div>
      </el-col>
      <el-col :span="6">
        <el-input placeholder="Search by code or name" prefix-icon="el-icon-search" v-model="filters.searchString">
        </el-input></el-col>
      <el-col :span="3.5">
        <el-tooltip class="item" effect="dark" content="Enter shipping details for Selected Items" placement="bottom">
          <el-button type="success" icon="el-icon-circle-check" @click="onShipping" />
        </el-tooltip>
      </el-col>
      <el-col :span="3.5">
        <el-tooltip class="item" effect="dark" content="Download Supplier wise order details" placement="bottom">
          <el-button type="primary" icon="el-icon-download" @click="dialogs.price = true" />
        </el-tooltip>
      </el-col>
      <el-col :span="3.5">
        <el-tooltip class="item" effect="dark" content="Reject Selected Items" placement="bottom">
          <el-button type="danger" icon="el-icon-circle-close" @click="dialogs.rejection = true" />
        </el-tooltip>
      </el-col>
      <el-col :span="3.5">
        <p class="mt-2">{{ itemsShowing }}</p>
      </el-col>
      <!-- <el-col :span="3.3"
        ><el-button type="success" icon="el-icon-circle-check" @click="dialogs.newShipping = true">Enter Details</el-button></el-col
      >
      <el-col :span="3"><el-button type="primary" icon="el-icon-download" @click="dialogs.price = true">Download</el-button></el-col>
      <el-col :span="2"><el-button type="danger" icon="el-icon-circle-close" @click="dialogs.rejection = true">Reject</el-button></el-col> -->
    </el-row>
    <el-table ref="elTable" :data="filteredData" :row-key="getRowKey" @selection-change="handleSelectionChange"
      style="width: 100%" border size="small" :cell-style="{ padding: '0', height: '20px' }"
      :header-cell-style="{ background: '#f1f1f5', color: 'black', textAlign: 'center' }">
      <el-table-column type="selection" width="35" :reserve-selection="true" />
      <el-table-column width="100">
        <template slot-scope="scope">
          <el-image :src="getImage(scope.row.product.imageLink)" style="width: 80px; height: 80px" class="my-2 rounded-lg"
            alt="Loading" lazy fit="fit" @click="previewURL(scope.row.product.imageLink)" />
        </template>
      </el-table-column>
      <el-table-column property="branch.code" label="Branch" align="center" :filters="filters.branches"
        :filter-method="filterHandler" />
      <el-table-column property="product.code" label="Code" :filters="filters.itemCodes" :filter-method="filterHandler" />
      <el-table-column property="product.name" label="Name" />
      <el-table-column property="orderId" label="Order" width="80" align="center" :filters="filters.orders"
        :filter-method="filterHandler" />
      <el-table-column label="Date" width="100" align="center">
        <template slot-scope="scope">
          {{ formatDate(scope.row.created_date) }}
        </template>
      </el-table-column>
      <el-table-column property="remaining_qty" label="Qty" width="60" align="center" />
      <el-table-column property="total" label="Total" width="80" align="center" />
      <el-table-column property="product.supplier" label="Supplier" width="100" align="center"
        :filters="filters.suppliers" :filter-method="filterHandler" />
      <!-- currentShippingQuantity -->
      <el-table-column property="" label="A.Qty" align="center">
        <template slot-scope="scope">
          <el-input v-model.number="scope.row.currentShippingQuantity" size="mini" :min="1"
            max="scope.row.igoalStock.stock" :disabled="!isRowSelected(scope.row)" />
        </template>
      </el-table-column>

      <el-table-column property="status" label="Tags" align="center" :filters="filters.tags"
        :filter-method="filterHandler">
        <template slot-scope="scope">
          <el-tag disable-transitions size="mini">{{ scope.row.orderStatus }}</el-tag>
          <el-tag type="success" size="mini">on hand : {{ scope.row.onHandQuantity || 0 }}</el-tag>
          <el-tag type="warning" size="mini" v-if="scope.row.shipped_qty !== '0'">Partially Shipped</el-tag>
          <el-tag type="warning" size="mini" v-else>Pending Details</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <!-- Shipping Details Dialog -->
    <new-shipping-dialog :dialog.sync="dialogs.newShipping" :shippingData="selected.items"
      @close="dialogs.newShipping = false" @success="_success" />
    <price-option-modal :dialog.sync="dialogs.price" @download="downloadExcelSheet" />
    <reject-reason-modal :visible.sync="dialogs.rejection" :rejectReason.sync="selected.rejectionReason"
      @save="onReject" />
  </div>
</template>

<script>
import { getImage } from "../../../common/utils";
import { DateService } from "../../../common/DateService";
import { imagePreviewHelper } from "../../../config/constants";
import { getProductsPendingShipping, rejectProducts, downloadSupplierOrderRequestExcel } from "./lib";
import NewShippingDialog from "@/views/Shipping/Pending/components/NewShippingDialog";
import RejectReasonModal from "../../../components/RejectReasonModal.vue";
import PriceOptionModal from "../../../components/priceOptionModal.vue";

export default {
  name: "PendingShippingDetails",
  components: {
    NewShippingDialog,
    RejectReasonModal,
    PriceOptionModal,
  },
  data() {
    return {
      pendingOrders: [],
      selected: {
        items: [],
        rejectionReason: "",
      },
      filters: {
        dates: "",
        branches: [],
        itemCodes: [],
        orders: [],
        suppliers: [],
        tags: [
          { text: "Pending Details", value: "SHIPPED" },
          { text: "Partially Shipped", value: "PARTIALLY_SHIPPED" },
        ],
        searchString: "",
      },
      dialogs: {
        newShipping: false,
        price: false,
        rejection: false,
      },
      isMounted: false,
    };
  },
  computed: {
    filteredData() {
      let searchString = this.filters.searchString;
      const filteredOrders = this.pendingOrders.filter(function (item) {
        return (
          item.product.name.toUpperCase().indexOf(searchString.toUpperCase()) >= 0 ||
          item.product.code.toUpperCase().indexOf(searchString.toUpperCase()) >= 0
        );
      });

      this.$nextTick(() => {
        this.triggerScrollEvent();
      });

      return filteredOrders;
    },
    itemsShowing() {
      if (!this.isMounted) return;
      const filteredItemsLength = this.$refs.elTable.tableData.length;
      const totalItemsLength = this.pendingOrders.length;
      return `Showing ${filteredItemsLength} / ${totalItemsLength} Items`;
    },
  },
  methods: {
    getImage: getImage,
    formatDate: DateService.formatDate,
    previewURL(imageLink) {
      this.$viewerApi(imagePreviewHelper(imageLink));
    },
    triggerScrollEvent() {
      const scrollEvent = new Event('scroll');
      window.dispatchEvent(scrollEvent);
    },
    isRowSelected(row) {
      return this.selected.items.find((item) => item.order_child_id === row.order_child_id);
    },
    async onInit() {
      await this.$store.dispatch("setLoading", true);
      let _dateQueryParam = {};
      if (this.filters.dates) {
        _dateQueryParam = {
          startDate: this.filters.dates[0],
          endDate: this.filters.dates[1],
        };
      }
      const _pending = await await getProductsPendingShipping(_dateQueryParam);
      this.pendingOrders = _pending?.map((obj) => {
        obj.carton_details = [
          {
            ctnNo: null,
            pcsCtn: null,
            ctns: null,
            quantity: 0,
            total: 0,
          },
        ];
        return obj;
      });
      this.$refs.elTable.clearSelection();

      this.setFilters();
      await this.$store.dispatch("setLoading", false);
    },
    setFilters() {
      this.filters.branches = [...new Set(this.pendingOrders.map((item) => item.branch.code))]
        .map((item) => ({ text: item, value: item }))
        .sort((a, b) => a.text.localeCompare(b.text));
      this.filters.itemCodes = [...new Set(this.pendingOrders.map((item) => item.product.code))]
        .map((item) => ({
          text: item,
          value: item,
        }))
        .sort((a, b) => a.text.localeCompare(b.text));
      this.filters.orders = [...new Set(this.pendingOrders.map((item) => item.orderId))].map((item) => ({ text: item, value: item }));
      this.filters.suppliers = [...new Set(this.pendingOrders.map((item) => item.product.supplier))]
        .map((item) => ({
          text: item,
          value: item,
        }))
        .sort((a, b) => a.text.localeCompare(b.text));
      // this.filters.tags = [...new Set(this.pendingOrders.map((item) => item.orderStatus))]
      //   .map((item) => ({
      //     text: item,
      //     value: item,
      //   }))
      //   .sort((a, b) => a.text.localeCompare(b.text));
    },
    handleSelectionChange(selectedRows) {
      this.selected.items = selectedRows;
    },
    filterHandler(value, row, column) {
      return this.resolve(column["property"], row) === value;
    },
    resolve(path, obj) {
      return path.split(".").reduce(function (prev, curr) {
        return prev ? prev[curr] : null;
      }, obj || self);
    },
    getRowKey(row) {
      return row.order_child_id;
    },
    _successMessage(message) {
      this.$notify({
        title: "Success",
        text: message,
        type: "success",
      });
    },
    _errorMessage(message) {
      this.$notify({
        title: "Error",
        text: message,
        type: "error",
      });
    },
    async _success() {
      this.dialogs.newShipping = false;
      await this.onInit();
    },
    async onShipSuccess() {
      this.dialogs.newShipping = false;
      await this.onInit();
    },
    async onReject() {
      if (!this.selected.items.length) {
        this.$notify({
          title: "Please select at least one item",
          type: "error",
        });
        return;
      }

      const _body = {
        data: [],
      };

      for (const { order_child_id, currentShippingQuantity, product } of this.selected.items) {
        if (currentShippingQuantity <= 0 || isNaN(currentShippingQuantity)) {
          this.$notify({
            title: `Please enter a valid rejection quantity : ${product.code}`,
            type: "error",
          });
          return;
        }

        _body.data.push({ orderChildId: order_child_id, qty: currentShippingQuantity, remark: this.selected.rejectionReason });
      }

      await this.$store.dispatch("setLoading", true);

      await rejectProducts(_body);
      await this.onInit();
      this.dialogs.rejection = false;
      await this.$store.dispatch("setLoading", false);
      this._successMessage("Selected products has been rejected successfully.!");
    },
    async downloadExcelSheet(showCost) {
      await this.$store.dispatch("setLoading", true);
      await downloadSupplierOrderRequestExcel(
        this.selected.items.map((item) => item.order_child_id),
        showCost,
        'SHIPPED_PENDING'
      );
      await this.$store.dispatch("setLoading", false);
      this._successMessage("Downloaded Successfully.!");
    },
    onShipping() {
      console.log(this.selected.items);

      if (!this.selected.items.length) {
        this.$notify({
          title: "Please select at least one item",
          type: "error",
        });
        return;
      }
      this.dialogs.newShipping = true;
    },
  },
  async mounted() {
    await this.onInit();
    this.isMounted = true;
  },
};
</script>

<style lang="css">
.el-icon-arrow-down {
  margin-left: 5px;
  color: black;
  font-weight: bolder;
}

.el-row {
  margin-bottom: 20px;
}
</style>
